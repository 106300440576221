import React from 'react'
import '@dexhunterio/swaps/lib/assets/style.css'
import Swap from '@dexhunterio/swaps'

const SwapScreen = () => {
  return (
    <div style={{
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: 'center', 
      padding: '1%', 
      flexDirection: 'column', 
      }}
      >

      <div>
        <img 
          src='https://static.wixstatic.com/media/2e14ea_673627c7b0374467adef944b9bf79f71~mv2.png/v1/fill/w_103,h_41,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/3%20Token%20Logo%20LQ.png' 
          alt='three digital gold tokens'
          style={{ 
            zIndex: '999',
          }} 
        />
      </div>
      
      <Swap
        orderTypes={["SWAP","LIMIT"]}
        colors={{"background":"#0E0F12","containers":"#191B23","subText":"#88919E","mainText":"#FFFFFF","buttonText":"#575755","accent":"#DCE775"}}
        theme="dark"
        width="450"
        partnerCode="digital-gold-ecosystem6164647231717866343868616e6766376c38336b7a647763373933766d6c67367336676774756861707872636832796737396b6873356b7868397a77687a6868397430386736746b777a33673772703870677864716332757a35656a6568356471757a3370306bda39a3ee5e6b4b0d3255bfef95601890afd80709"
        partnerName="Digital-Gold-Ecosystem"
        displayType="FULL"
        style={{ 
          zIndex: '999', 
          boxShadow: '0px 15px 20px rgba(0, 0, 0, 0.9)', 
          borderRadius: '30px'
        }}
        defaultToken='16fdd33c86af604e837ae57d79d5f0f1156406086db5f16afb3fcf5144474f4c44'
      />
      <a 
        href ="https://www.digitalgoldecosystem.com/token-swap" 
        style={{
          marginTop: '2%',
          padding: '1% 2%',
          borderRadius: '3px',
          color: '#575755',
          backgroundColor: '#DCE775',
          textDecoration: 'none',
          fontWeight: 'bold'
        }}
        >Back</a>
    </div>
    
  )
}

// digital gold - 16fdd33c86af604e837ae57d79d5f0f1156406086db5f16afb3fcf5144474f4c44
// digital silver - 59515c449f1ec1d21a4b2d93013337d13b29d74c3a665a11d5b7fc6644534c5652
// digital diamond - 96ad5ab136d2193dda2afb662285b93e48d265e14df59ee0f33925ae4447454d
export default SwapScreen
