import './App.css';
import SwapScreen from './SwapScreen';

function App() {
  return (
    <div className="App">
      <SwapScreen />
    </div>
  );
}

export default App;
